// MiddleSchool
import React from 'react';
import MiddleSchool from 'views/MiddleSchool';

const MiddleSchoolPage = () => {
  return <MiddleSchool/>;
};

export default MiddleSchoolPage;

