import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form } from './components';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import Typography from '@mui/material/Typography';

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

const MiddleSchool= () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
                   <Container  maxWidth={0.78}  paddingTop={'0 !important'}>
          <Grid>
          <Typography variant={'h4'} sx={{ fontWeight: 700 }}
              align={'center'}>
               Middle School <br/>
              </Typography>
              <Typography component='p' align={'justify'} color="text.secondary">
             <br/> At the middle school, more novel subjects are introduced to students. The curriculum is enhanced and widens to improve student’s abilities. Intermediate level provides a development from the defensive, guided environment of the primary towards an autonomous approach designed. The Scholastic areas in the Middle School curriculum delves into deeper content knowledge in the areas of Mathematics, General Science, Social Science, Languages and Life Skills. The students are facilitated to get a hands-on experience in the state-of-the-art laboratories in areas of Science, Mathematics, Computer Science and Skill subjects. <br />
             The middle schools aims to educate the students individually, students are offered with regular and comprehensively structured Periodic Tests, Half Yearly Examinations and Annual Examinations.
             <br/>
              </Typography>
          </Grid>

        </Container>
        <Container maxWidth={0.78}  paddingTop={'0 !important'}>
      <Form />
      </Container>
    </Main>
  );
};

export default MiddleSchool;
